@font-face {
  font-family: "Sherika";
  src: url("../fonts/Sherika-Light.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Sherika";
  src: url("../fonts/Sherika-Regular.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Sherika";
  src: url("../fonts/Sherika-Medium.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Sherika";
  src: url("../fonts/Sherika-Bold.otf") format("opentype");
  font-weight: 800;
}
@font-face {
  font-family: "Sherika";
  src: url("../fonts/Sherika-ExtraBold.otf") format("opentype");
  font-weight: 900;
}

$primary: #1139cb;
$primary-hover: #98ca43;
$bodyBG: #e3eeff;
$footerBG: #1d396b;
$copyrightBG: #060c3c;
$text_primary: #1d396b;
$transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);

@mixin ImgWhiteToBlack() {
  filter: brightness(0);
}
@mixin ImgBlackToWhite() {
  filter: brightness(1);
}
@mixin CenterThings($vertical, $horizontal) {
  display: flex;
  align-items: $vertical;
  justify-content: $horizontal;
}
@mixin ImageFit($height, $contain) {
  height: $height;
  img {
    width: 100%;
    height: 100%;
    object-fit: $contain;
  }
}
@mixin Headings($align, $mb, $h1size, $psize) {
  .heading {
    text-align: $align;
    margin-bottom: $mb;
    h1 {
      font-size: $h1size;
      font-weight: 700;
      margin-bottom: 30px;
      color: #1d396b;
    }
    p {
      font-size: $psize;
      line-height: 25px;
      color: #1d396b;
    }
  }
}

body {
  font-family: "Sherika";
  background-color: $bodyBG;
  font-weight: 600;
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50%;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.btn-primary {
  background-color: $primary;
  border-color: $primary;
  padding: 15px 25px !important;
  border-radius: 20px;
  color: #fff !important;
  font-size: 15px;
  letter-spacing: 0.9px;
  font-weight: 700;
  &:hover {
    background-color: $primary-hover;
    border-color: $primary-hover;
  }
  svg {
    margin-left: 8px !important;
  }
}
// Navbar
.navbar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
  .navbar-nav {
    align-items: center;
    .nav-link {
      color: #000;
      margin: 0 15px;
      font-weight: 700;
    }
    .nav-item {
      &.dropdown {
        .dropdown-toggle {
          &:after {
            display: none;
          }
          svg {
            margin-left: 5px;
          }
        }
        .dropdown-menu {
          a {
            font-weight: 600;
          }
        }
      }
    }
  }
}
//juno_banner
.juno_banner {
  padding: 10em 0;
  position: relative;
  .juno_banner_bg {
    position: absolute;
    top: 8%;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  .container {
    position: relative;
    .caption {
      margin-top: 40px;
    }
    .caption_text {
      margin-bottom: 50px;
      h1 {
        font-size: 45px;
        font-weight: 700;
        color: #213053;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 40px;
        opacity: 0.7;
        margin-bottom: 30px;
        font-weight: 700;
      }
      p {
        font-weight: 700;
        font-size: 20px;
        opacity: 0.5;
        line-height: 34px;
        letter-spacing: 0.5px;
      }
    }
  }
}
.juno_common_banner {
  padding: 8em 0 1em;
  position: relative;
  background: #fff;
  &.spacing {
    padding: 12em 0 1em;
  }
  .juno_banner_bg {
    position: absolute;
    top: 0;
    pointer-events: none;
    width: 100%;
    height: 100%;
  }
  .container {
    position: relative;
    .caption_text {
      margin-bottom: 50px;
      h1 {
        font-size: 45px;
        font-weight: 700;
        margin-bottom: 38px;
        color: #213053;
      }
      h3 {
        font-size: 30px;
        color: rgba(29, 57, 107, 0.7);
        margin-bottom: 30px;
      }
      h2 {
        font-size: 40px;
        opacity: 0.7;
        margin-bottom: 50px;
      }
      p {
        font-size: 17px;
        line-height: 28px;
        opacity: 0.7;
        font-weight: 700;
      }
    }
  }
}
//featured_all
.featured_all {
  padding: 4em 0;
  .featured_box {
    padding: 60px 30px;
    text-align: center;
    border-radius: 40px;
    transition: $transition;
    background: $bodyBG;
    height: 100%;
    &:hover {
      filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.08));
      .featured_wrap {
        transform: translateY(-10%);
      }
    }
    .featured_wrap {
      transition: $transition;
    }
    .featured_img {
      img {
        width: 150px;
        display: block;
        margin: auto;
        margin-bottom: 40px;
      }
    }
    .featured_desc {
      position: relative;
      h4 {
        color: $text_primary;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        color: #848484;
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 25px;
        margin-bottom: 0;
        font-weight: 600;
      }
    }
  }
}

//juno_speciality
.juno_speciality {
  position: relative;
  padding: 5em 0 0;
  .speciality_text {
    position: absolute;
    top: 0;
  }
  .speciality_box {
    background: #fff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    padding: 50px 50px 100px;
    position: relative;
    .speciality_img {
      transition: $transition;
      .speciality_featured {
        width: 530px;
        position: absolute;
        top: 15%;
        z-index: 9;
      }
      .animation {
        position: absolute;
        width: 100px;
      }
    }
    .speciality_desc {
      h1 {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 30px;
        color: #213053;
      }
      h4 {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 30px;
        color: #656565;
      }
      p {
        margin-bottom: 30px;
        color: #9db1bc;
      }
    }
  }
}
//juno_key_highlights
.juno_key_highlights {
  padding: 5em 0 0;
  background: #fff;
  .heading {
    h1 {
      font-size: 45px;
      font-weight: 800;
      color: rgba(52, 58, 64, 1);
    }
  }
  .juno_key_box {
    background: #fff;
    box-shadow: 4px 4px 25px 1px #0000001f;
    border-radius: 23px;
    padding: 30px;
    margin-bottom: 35px;
    transition: $transition;
    height: 85%;
    &:hover {
      background: $primary;
      color: #fff;
      .juno_img {
        img {
          @include ImgBlackToWhite();
        }
      }
      .juno_desc {
        p {
          color: #fff;
        }
      }
    }
    .juno_img {
      @include ImageFit(50px, contain);
      margin-bottom: 20px;
      img {
        @include ImgWhiteToBlack();
        width: auto;
      }
    }
    .juno_desc {
      h2 {
        font-size: 22px;
        font-weight: 700;
        line-height: 30px;
      }
      p {
        font-size: 15px;
        line-height: 23px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.7);
      }
    }
  }
}
//juno_changemaking
.juno_changemaking {
  padding: 5em 0;
  background: #fff url("../img/change_making_bg.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 130%;
  .left_content {
    padding: 5em;
    h2 {
      font-size: 50px;
      line-height: 60px;
      color: $footerBG;
      font-weight: 700;
    }
    p {
      font-size: 28px;
      color: $footerBG;
    }
  }
  .juno_changemaking_box {
    background: #fff;
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 40px;
    height: 180px;
    @include CenterThings(center, center);
    box-shadow: 1px 0px 10px 1px rgb(0 0 0 / 5%);
    .image_box {
      @include ImageFit(80px, contain);
      width: 100%;
    }
  }
}
//juno_unique_character
.juno_unique_character {
  padding: 5em 0 0;
  @include Headings(center, 50px, 45px, 18px);
  .juno_unique_box {
    text-align: center;
    background: #fff;
    padding: 30px;
    margin-bottom: 30px;
    transition: $transition;
    border-radius: 16px;
    border: 2px solid #fff;
    height: 80%;
    &:hover {
      background: $text_primary;
      .juno_unique_desc {
        h2 {
          color: #fff;
        }
        p {
          color: rgba(255, 255, 255, 0.7);
        }
      }
    }
    .juno_unique_icon {
      margin-bottom: 20px;
    }
    .juno_unique_desc {
      h2 {
        font-size: 24px;
        margin-bottom: 20px;
        font-weight: 700;
      }
      p {
        color: #848484;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 25px;
        margin-bottom: 20px;
      }
    }
  }
}
.juno_testimonial {
  padding: 5em 0 0;
  @include Headings(center, 80px, 40px, 18px);
  .testimonail_main_box {
    background: #213053;
    .swiper-button-next:after,
    .swiper-button-prev:after {
      color: #fff;
    }
    .swiper-pagination {
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          background: #fff;
        }
      }
    }
    .testimonial_box {
      padding: 50px 150px;
      color: #fff;
      position: relative;
      .testimonial_image {
        position: relative;
        overflow: hidden;
        height: 280px;
        &:before {
          content: "";
          position: absolute;
          top: 60px;
          left: 60px;
          width: 210px;
          height: 100%;
          background: $footerBG;
          border-radius: 15px;
          z-index: 2;
        }
        &:after {
          content: "";
          position: absolute;
          top: 0;
          right: 15px;
          width: 200px;
          height: 100%;
          background: url("../img//testimonial/text_bg.png") no-repeat 100%;
          z-index: 1;
          filter: invert(1);
        }
        img {
          position: relative;
          z-index: 9;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .testimonial_content {
        p {
          font-size: 16px;
          line-height: 36px;
          margin-bottom: 30px;
        }
        .designation {
          h4 {
            font-size: 14px;
          }
          p {
            opacity: 0.5;
            font-size: 13px;
            line-height: 25px;
          }
        }
      }
    }
  }
}
//be_partner
.be_partner {
  padding: 5em 0;
  .wrap {
    background: #fff;
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 50px;
    position: relative;
    img {
      position: absolute;
      top: 0;
      right: 0;
    }
    .partner_desc {
      h1 {
        color: $text_primary;
        font-size: 44px;
        line-height: 58px;
        margin-bottom: 30px;
        font-weight: 700;
      }
      P {
        font-size: 18px;
        color: rgba(29, 57, 107, 0.7);
        margin-bottom: 30px;
        font-weight: 700;
      }
    }
  }
}
/*********************About Us********************************/
.team_consist {
  background: #fff;
  position: relative;
  padding: 3em 0;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: #1139cb;
    width: 70%;
    height: 100%;
    border-radius: 0 30px 30px 0;
  }
  .container {
    position: relative;
    p {
      font-size: 20px;
      line-height: 45px;
      margin-bottom: 0;
      color: #fff;
    }
  }
}
.our_evolution {
  padding: 5em 0;
  background: #fff;
  h1 {
    font-weight: 700;
    line-height: 88px;
    color: #213053;
    font-size: 45px;
  }
  p {
    color: rgba(51, 51, 51, 0.8);
    line-height: 30px;
    font-weight: 700;
  }
}
.juno_campus_team {
  padding: 5em 0 0;
  background: #1139cb;
  position: relative;
  .campus_team {
    position: absolute;
    width: 100%;
    max-width: 700px;
    bottom: 0;
  }
  .juno_campus_team_desc {
    padding: 20px;
    color: #fff;
    h1 {
      font-size: 38px;
      line-height: 50px;
      margin-bottom: 30px;
      font-weight: 700;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        background: #fff;
        padding: 20px;
        list-style: none;
        margin-bottom: 20px;
        color: #000;
        border-radius: 20px;
        transition: $transition;
        font-weight: 700;
        &:hover {
          background: #b1beee;
          margin-left: 5px;
        }
      }
    }
  }
}
.major_findings {
  padding: 5em 0;
  background: #fff;
  @include Headings(center, 80px, 45px, 20px);
  .specification {
    background: rgba(59, 71, 188, 0.1);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 30px;
    margin-top: 80px;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 45%;
      width: 0.5px;
      height: 100%;
      background: rgba(0, 0, 0, 0.2);
    }
    .circle_left,
    .circle_right {
      position: absolute;
      width: 300px;
    }
    .circle_left {
      bottom: -5%;
      left: -15%;
    }
    .circle_right {
      top: -5%;
      right: -5%;
    }
    h1 {
      font-size: 24px;
      line-height: 40px;
      font-weight: 700;
      margin-bottom: 40px;
      &.dark {
        color: rgba(0, 0, 0, 0.7);
      }
      &.blue {
        color: #3c78f2;
      }
    }
    .lists {
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      padding: 15px;
      font-weight: 700;
      &.dark {
        color: rgba(0, 0, 0, 0.7);
      }
      &.blue {
        color: #3c78f2;
      }
      span {
        color: #4476bc;
        margin-right: 5px;
        font-weight: 600;
      }
    }
  }
}
.about_steps {
  padding: 5em 0 1em;
  .timeline_box_container {
    background: url("../img/about_timeline/dots.png") no-repeat center;
    .timeline_box {
      background: #fff;
      box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.03);
      border-radius: 16px;
      padding: 30px;
      height: 300px;
      margin: 0 30px 100px;
      text-align: center;
      transition: $transition;
      border: 2px solid #fff;
      &:hover {
        background: $text_primary;
        .desc {
          p {
            color: #fff;
          }
        }
      }
      .icon {
        margin-bottom: 30px;
      }
      .desc {
        p {
          margin-bottom: 0;
          color: #848484;
          font-size: 16px;
          letter-spacing: 0.5px;
          line-height: 30px;
          font-weight: 700;
        }
      }
    }
  }
}
.our_mission_vision {
  padding: 2em 0 3em;
  .our_mission_vision_box {
    background: #fff;
    box-shadow: 3.65px 0.91px 26px 1px rgba(17, 57, 203, 12%);
    border-radius: 27px;
    padding: 50px 0 0;
    .desc {
      h1 {
        font-weight: 700;
        font-size: 44px;
        line-height: 62px;
        color: #1d396b;
      }
      p {
        margin-bottom: 30px;
        color: #1d396b;
        opacity: 0.8;
        span {
          font-weight: 700;
          font-size: 22px;
          opacity: 1;
        }
      }
    }
  }
}
.our_values {
  padding: 5em 0;
  @include Headings(center, 40px, 45px, 18px);
  .value_box {
    text-align: center;
    padding: 30px;
    border-radius: 40px;
    transition: $transition;
    background: $bodyBG;
    height: 100%;
    &:hover {
      filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.08));
      .featured_wrap {
        transform: translateY(-5%);
      }
    }
    .featured_wrap {
      transition: $transition;
    }

    .icons {
      margin-bottom: 30px;
      img {
        width: 120px;
      }
    }
    .desc {
      font-size: 20px;
      line-height: 25px;
      color: #1d396b;
      font-weight: 700;
    }
  }
}

/****** Teams ***********/
.education_solution_visionaries {
  background: $primary;
  padding: 5em 0 10em;
  .heading {
    text-align: center;
    color: #fff;
    h1 {
      font-size: 54px;
      line-height: 64px;
      margin-bottom: 30px;
      font-weight: 700;
    }
    p {
      font-size: 15px;
      margin-bottom: 30px;
    }
  }
  .vision_box {
    position: relative;
    &:hover {
      .vision_desc {
        opacity: 1;
        visibility: visible;
      }
    }
    .vision_image {
      height: 350px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .vision_desc {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #4f8def;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: $transition;
      color: #fff;
      opacity: 0;
      visibility: hidden;
      h4 {
        font-size: 34px;
        line-height: 32px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
        opacity: 0.6;
      }
    }
  }
}
.the_dream_team {
  background: #fff;
  padding: 6em 0 0;
  position: relative;
  // &.bg_light {
  //   background: #e7f3ff !important;
  // }
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50px;
    background-size: 100%;
  }
  &:before {
    top: -35px;
    background: url("../img/bg_top.png") no-repeat;
  }
  // &:after {
  //   bottom: -4%;
  //   background: url("../img/bg_bottom.png") no-repeat;
  //   z-index: 1;
  // }
  .heading {
    text-align: center;
    color: #283646;
    h1 {
      font-size: 54px;
      line-height: 64px;
      margin-bottom: 30px;
      font-weight: 800;
    }
    p {
      font-size: 15px;
      margin-bottom: 40px;
      font-weight: 700;
    }
  }
  .team_box {
    margin-bottom: 80px;
    text-align: center;
    .team_profile {
      width: 150px;
      height: 150px;
      overflow: hidden;
      border-radius: 50%;
      margin: auto;
      margin-bottom: 40px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
    .team_desc {
      h3 {
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #283646;
        font-weight: 700;
      }
      p {
        font-size: 16px;
        line-height: 150%;
        color: #77808b;
      }
    }
  }
}
/********* Media *********/
.annual_celebration {
  padding: 5em 0;
  background: #fff;
  .heading {
    color: #213053;
    h1 {
      font-size: 54px;
      line-height: 64px;
      margin-bottom: 30px;
      font-weight: 700;
    }
    p {
      font-size: 20px;
      margin-bottom: 40px;
      font-weight: 600;
    }
  }
  .all_events {
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fill, minmax(136px, 2fr));
    grid-auto-rows: minmax(120px, auto);
    position: relative;
    .left,
    .right {
      position: absolute;
    }
    .left {
      top: 0;
    }
    .right {
      bottom: 0;
      right: 0;
    }
    .hide {
      width: 100%;
      height: 100%;
      &.ratio2x2 {
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        -ms-grid-row-span: 2;
        grid-row-end: span 2;
      }
    }
    .evens_grid {
      position: relative;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &.ratio2x1 {
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
      }
      &.ratio2x2 {
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        -ms-grid-row-span: 2;
        grid-row-end: span 2;
      }
      &.ratio2x4 {
        -ms-grid-column-span: 2;
        grid-column-end: span 2;
        -ms-grid-row-span: 4;
        grid-row-end: span 4;
      }
      &.ratio4x2 {
        -ms-grid-column-span: 4;
        grid-column-end: span 4;
        -ms-grid-row-span: 2;
        grid-row-end: span 2;
      }
    }
  }
}
.news_and_videos {
  padding: 4em 0 2em;
  background: #fff;
  .heading {
    color: #213053;
    h1 {
      font-size: 45px;
      line-height: 64px;
      margin-bottom: 30px;
      font-weight: 700;
    }
  }
  .news_image {
    height: 250px;
    overflow: hidden;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  // .video_box {
  //   position: relative;
  //   .play_btn {
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     transform: translate(-50%, -50%);
  //   }
  // }
}

/*************** Why Juno Campus ******************/
.source_truth {
  padding: 5em 0;
  .wrap {
    background: #fff;
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 10px 50px;
    .partner_desc {
      h1 {
        color: $text_primary;
        font-size: 47px;
        line-height: 58px;
        margin-bottom: 30px;
        font-weight: 700;
      }
      P {
        font-size: 20px;
        color: rgba(29, 57, 107, 0.7);
        margin-bottom: 30px;
        line-height: 35px;
      }
    }
  }
}
.built_to_adapt {
  padding: 2em 0 3em;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: -5%;
    width: 100%;
    height: 50px;
    background-size: 100%;
    background: url("../img/bg_top.png") no-repeat;
    z-index: 1;
  }
  .img-fluid {
    position: absolute;
    bottom: 0;
    width: 600px;
    z-index: 2;
  }
  .desc {
    h1 {
      font-size: 48px;
      line-height: 88px;
      color: #213053;
      margin-bottom: 20px;
      font-weight: 700;
    }
    p {
      font-size: 20px;
      line-height: 26px;
      color: #213053;
    }
  }
}
.multi_level_domain {
  padding: 5em 0;
  background: #fff;
  @include Headings(center, 50px, 40px, 18px);
  .multi_level_domain_tab {
    ul {
      margin: 0;
      padding: 0;
      li {
        background: #fff;
        padding: 15px 20px;
        color: #000;
        list-style: none;
        box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
        border-radius: 20px;
        margin-bottom: 20px;
      }
    }
  }
  .multi_level_box {
    background: #ffffff;
    box-shadow: 3.65px 0.91px 26px 1px rgb(0 0 0 / 10%);
    border-radius: 20px;
    padding: 25px 15px;
    text-align: center;
    transition: $transition;
    margin: 20px;
    &:hover {
      background: $footerBG;
      .multi_level_title {
        color: #fff;
      }
      .multi_level_icon {
        filter: brightness(0) invert(1);
      }
    }
    &.space {
      margin-top: 50px;
    }
    .multi_level_icon {
      margin-bottom: 10px;
    }
    .multi_level_title {
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #1d396b;
    }
  }
}
.getwell_other {
  padding: 5em 0;
  .heading {
    text-align: center;
    background: url("../img/get_well_bg.png") no-repeat center;
    background-size: 100%;
    padding: 20px;
    margin-bottom: 5em;
    h1 {
      font-size: 50px;
      font-weight: 700;
      color: #213053;
      margin-bottom: 30px;
    }
    p {
      color: rgba(29, 57, 107, 0.7);
      font-weight: 700;
    }
  }
  .security_list {
    margin: 0;
    padding: 0;
    display: flex;
    li {
      background: #ffffff;
      box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
      border-radius: 30px;
      padding: 20px;
      list-style: none;
      text-align: center;
      width: calc(100% / 6);
      margin: 15px;
      transition: $transition;

      border: 2px solid transparent;
      &:hover {
        transform: translateY(-5%);
        border: 2px solid #699eee;
      }
      .security_list_icon {
        margin-bottom: 25px;
      }
      .security_list_title {
        font-size: 14px;
        line-height: 19px;
        color: #213053;

        font-weight: 700;
      }
    }
  }
}
.features {
  padding: 5em 0;
  background: url("../img/features_bg.png") no-repeat left center;
  background-size: 90% 100%;
  @include Headings(center, 80px, 45px, 18px);
  .heading {
    p {
      font-weight: 700;
    }
  }
  .feature_box {
    background: #fff;
    box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 60px 30px;
    height: 480px;
    position: relative;
    margin-bottom: 30px;
    transition: $transition;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &:hover {
      background: rgba(239, 246, 255, 0.5);
    }
    .icons {
      position: absolute;
      top: 20px;
      right: 20px;
      img {
        width: 140px;
      }
    }
    h1 {
      font-size: 28px;
      line-height: 34px;
      color: #213053;
      margin-bottom: 40px;
      font-weight: 800;
    }
    p {
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 0.5px;
      color: rgba(0, 0, 0, 0.7);
      margin-bottom: 0;
      font-weight: 700;
    }
  }
}
/************* offer_banner *****************/
.offer_banner {
  background: #fff;
  position: relative;
  padding: 4em 0;
  img.feature {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 9;
    transform: translate(-50%, -2%);
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: -5%;
    background: url("../img/bg_bottom.png") no-repeat;
    background-size: 100%;
    z-index: 1;
  }
}
.offer_feature {
  padding: 5em 0;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50px;
    bottom: -14px;
    background: url("../img/bg_top.png") no-repeat;
    background-size: 100%;
    z-index: 1;
  }
  .feature_box {
    background: #ffffff;
    box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    padding: 30px 20px;
    margin-bottom: 30px;
    transition: $transition;
    &:hover {
      background: #213053;
      h1 {
        color: #fff;
      }
      ul {
        li {
          color: #fff;
        }
      }
    }
    h1 {
      font-size: 30px;
      line-height: 40px;
      text-align: center;
      color: #213053;
      font-weight: 700;
      margin-bottom: 20px;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style-type: none;
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 30px;
        font-weight: 700;
      }
    }
  }
}
.student_lyfcycle {
  background: #fff;
  position: relative;
  padding: 0 0 3em;
  .student_lyfcycle_process {
    background: url("../img/right_arrow.png") no-repeat center center;
    background-size: 80%;
    padding: 30px;
    margin: 80px 0;
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      li {
        list-style: none;
        transition: $transition;
        &:hover {
          transform: translateY(-5%);
        }
      }
    }
  }
  .heading {
    text-align: center;
    margin-bottom: 50px;
    h2 {
      margin-top: 20px;
    }
    h1,
    h2 {
      font-size: 44px;
      line-height: 53px;
      color: #213053;
      font-weight: 700;
    }
    .line {
      width: 50%;
      height: 2px;
      border: 3px solid #064b72;
      margin: auto;
    }
  }
  .row {
    .col-lg-4 {
      &:nth-child(1) {
        .workflow {
          background: #eff6ff;
        }
      }
      &:nth-child(2) {
        .workflow {
          background: #dbeef4;
        }
      }
      &:nth-child(3) {
        .workflow {
          background: #e3f8ff;
        }
      }
      &:nth-child(4) {
        .workflow {
          background: #dbeef4;
        }
      }
      &:nth-child(5) {
        .workflow {
          background: #b9cde5;
        }
      }
      &:nth-child(6) {
        .workflow {
          background: #8eb4e3;
        }
      }
    }
  }
  .workflow {
    padding: 30px;
    transition: $transition;
    margin-bottom: 30px;
    border-radius: 30px;
    box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
    height: 330px;
    position: relative;
    margin: 15px;
    &:hover {
      background: #fff !important;
    }
    h1 {
      font-size: 28px;
      line-height: 34px;
      color: #1d396b;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      font-size: 16px;
      line-height: 30px;
      font-weight: 700;
      color: rgba(0, 0, 0, 0.7);
    }
    .dotted {
      position: absolute;
      bottom: 10%;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}
.success_story {
  padding: 5em 0 2em;
  background: #fff;
  .heading {
    h2 {
      font-weight: 700;
      margin-bottom: 40px;
      font-size: 40px;
      color: #1d396b;
    }
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: #213053;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #1d396b;
    &:after {
      font-size: 14px;
      color: #fff;
    }
  }
  .wraped {
    background: #ffffff;
    box-shadow: 3.65px 0.91px 26px 1px #d0e7f7;
    border-radius: 20px;
    padding: 40px;
    margin: 30px;
    height: 650px;
    .desc {
      .icon_box {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .icon {
          margin-right: 30px;
          img {
            width: 70px;
          }
        }
      }
      .name {
        h3 {
          font-size: 24px;
          color: #213053;
          font-weight: 700;
        }
        p {
          font-size: 15px;
          color: rgba(33, 48, 83, 0.7);
          margin-bottom: 0;
        }
      }
      h1 {
        font-size: 32px;
        line-height: 75px;
        color: #213053;
        font-weight: 700;
      }
      h4 {
        color: #213053;
        font-weight: 700;
      }
      p {
        font-size: 16px;
        text-align: justify;
        color: rgba(33, 48, 83, 0.7);
        margin-bottom: 20px;
      }
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          position: relative;
          margin-bottom: 10px;
          font-size: 16px;
          color: rgba(33, 48, 83, 0.7);
          padding-left: 40px;
          &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 18px;
            height: 18px;
            background: #213053;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
.proud_customer {
  padding: 3em 0 0;
  background: #fff;
  position: relative;
  @include Headings(center, 50px, 45px, 20px);
  .heading {
    p {
      font-weight: 700;
    }
  }
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 50px;
    background-size: 100%;
  }
  &:after {
    bottom: -4%;
    background: url("../img/bg_bottom.png") no-repeat;
    z-index: 1;
  }
}
.proud_customer_list {
  padding: 8em 0;
  position: relative;
  .list_box {
    margin-bottom: 40px;
    .list_box_img {
      background: #ffffff;
      box-shadow: 3.65px 0.91px 26px 1px #d0e7f7;
      border-radius: 10px;
      padding: 30px;
      text-align: center;
      margin-bottom: 20px;
      height: 200px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .heading {
      h1 {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        font-weight: 700;
      }
    }
  }
}
.contact_form {
  padding: 5em 0;
  background: #fff;
  @include Headings(center, 50px, 45px, 20px);
  .form_wrap {
    background: #ffffff;
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 50px 30px;
    .form-floating {
      margin-bottom: 30px;
      label {
        font-weight: 700;
      }
      input,
      select,
      textarea {
        background: #e0eeff;
        border: 0;
      }
    }
  }
}
//Footer
footer {
  background-color: $footerBG;
  padding: 5em 0;
  position: relative;
  overflow: hidden;
  font-weight: 500;
  .footer_left_img,
  .footer_top_img {
    position: absolute;
  }
  .footer_left_img {
    left: 0;
    top: 50%;
    width: 150px;
    transform: translate(0, -50%);
  }
  .footer_top_img {
    right: 5%;
    top: 0;
    width: 300px;
    transform: translate(0, -25%);
  }
  .newsletter {
    padding-bottom: 40px;
    margin-bottom: 40px;
    border-bottom: 1px solid #ffffff;
    .newsletter_content {
      color: #fff;
      h1 {
        font-size: 40px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        color: #fefeff;
        font-size: 16px;
        line-height: 28px;
        opacity: 0.8;
        letter-spacing: 0.5px;
        margin-bottom: 0;
      }
    }
    .newsletter_form_box {
      .newsletter_form {
        position: relative;
        input {
          width: 100%;
          height: 66px;
          border: 1px solid #b4b6c4;
          border-radius: 20px;
        }
        button {
          position: absolute;
          top: 6px;
          right: 6px;
        }
      }
    }
  }
  .address_box {
    display: flex;
    .address {
      padding-left: 15px;
      color: #fff;
      h4 {
        font-size: 16px;
        line-height: 26px;
      }
      p {
        font-size: 14px;
        line-height: 26px;
        opacity: 0.8;
        font-weight: 400;
        color: #b4b6c4;
        letter-spacing: 0.5px;
      }
    }
  }
  .useful_links {
    color: #fefeff;
    h3 {
      font-size: 18px;
      margin-bottom: 20px;
      font-weight: 600;
    }
    a {
      display: block;
      color: #b4b6c4;
      font-size: 14px;
      margin-bottom: 10px;
      text-decoration: none;
      opacity: 0.8;
      letter-spacing: 0.5px;
    }
    .social_links {
      display: flex;
      a {
        margin-right: 10px;
      }
    }
  }
}
.copyright {
  padding: 2em 0;
  background-color: $copyrightBG;
  text-align: center;
  p {
    margin-bottom: 0;
    color: #fff;
    letter-spacing: 0.5px;
  }
}
