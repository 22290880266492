@font-face {
  font-family: "Sherika";
  src: url("../fonts/Sherika-Light.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Sherika";
  src: url("../fonts/Sherika-Regular.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Sherika";
  src: url("../fonts/Sherika-Medium.otf");
  font-weight: 700;
}
@font-face {
  font-family: "Sherika";
  src: url("../fonts/Sherika-Bold.otf") format("opentype");
  font-weight: 800;
}
@font-face {
  font-family: "Sherika";
  src: url("../fonts/Sherika-ExtraBold.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "Montserrat";
  src: url("../fonts/Montserrat/static/Montserrat-SemiBold.ttf");
  font-weight: 300;
}

body {
  font-family: "Calibri";
  background-color: #e3eeff;
  font-weight: 600;
}
.CompanyName{
  color: #0f4ea3;
  display: inline;
  vertical-align: middle;
  font-size: 30px;
  font-weight:600;
  margin-left: 5px;
  font-family: "Calibri";
}
.navbar .navbar-brand img{
  width: 105px;
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50%;
  animation: spin 4s linear infinite;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.btn-primary {
  background-color: #1139cb;
  border-color: #1139cb;
  padding: 15px 25px !important;
  border-radius: 20px;
  color: #fff !important;
  font-size: 15px;
  letter-spacing: 0.9px;
  font-weight: 700;
}
.btn-primary:hover {
  background-color: #98ca43;
  border-color: #98ca43;
}
.btn-primary svg {
  margin-left: 8px !important;
}

.navbar {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;
}
.navbar .navbar-nav {
  align-items: center;
}
.navbar .navbar-nav .nav-link {
  color: #000;
  margin: 0 15px;
  font-weight: 700;
}
.navbar .navbar-nav .nav-item.dropdown .dropdown-toggle:after {
  display: none;
}
.navbar .navbar-nav .nav-item.dropdown .dropdown-toggle svg {
  margin-left: 5px;
}
.navbar .navbar-nav .nav-item.dropdown .dropdown-menu a {
  font-weight: 600;
}

.juno_banner {
  padding: 10em 0;
  position: relative;
}
.juno_banner .juno_banner_bg {
  position: absolute;
  top: 8%;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.juno_banner .container {
  position: relative;
}
.juno_banner .container .caption {
  margin-top: 40px;
}
.juno_banner .container .caption_text {
  margin-bottom: 50px;
}
.juno_banner .container .caption_text h1 {
  font-size: 45px;
  font-weight: 700;
  color: #213053;
  margin-bottom: 20px;
}
.juno_banner .container .caption_text h2 {
  font-size: 40px;
  opacity: 0.7;
  margin-bottom: 30px;
  font-weight: 700;
}
.juno_banner .container .caption_text p {
  font-weight: 700;
  font-size: 20px;
  opacity: 0.5;
  line-height: 34px;
  letter-spacing: 0.5px;
}

.juno_common_banner {
  padding: 8em 0 1em;
  position: relative;
  background: #fff;
}
.juno_common_banner.spacing {
  padding: 12em 0 1em;
}
.juno_common_banner .juno_banner_bg {
  position: absolute;
  top: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
}
.juno_common_banner .container {
  position: relative;
}
.juno_common_banner .container .caption_text {
  margin-bottom: 50px;
}
.juno_common_banner .container .caption_text h1 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 38px;
  color: #213053;
}
.juno_common_banner .container .caption_text h3 {
  font-size: 30px;
  color: rgba(29, 57, 107, 0.7);
  margin-bottom: 30px;
}
.juno_common_banner .container .caption_text h2 {
  font-size: 40px;
  opacity: 0.7;
  margin-bottom: 50px;
}
.juno_common_banner .container .caption_text p {
  font-size: 17px;
  line-height: 28px;
  opacity: 0.7;
  font-weight: 700;
}

.featured_all {
  padding: 4em 0;
}
.featured_all .featured_box {
  padding: 60px 30px;
  text-align: center;
  border-radius: 40px;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background: #e3eeff;
  height: 100%;
}
.featured_all .featured_box:hover {
  filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.08));
}
.featured_all .featured_box:hover .featured_wrap {
  transform: translateY(-10%);
}
.featured_all .featured_box .featured_wrap {
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.featured_all .featured_box .featured_img img {
  width: 150px;
  display: block;
  margin: auto;
  margin-bottom: 40px;
}
.featured_all .featured_box .featured_desc {
  position: relative;
}
.featured_all .featured_box .featured_desc h4 {
  color: #1d396b;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.featured_all .featured_box .featured_desc p {
  color: #848484;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 25px;
  margin-bottom: 0;
  font-weight: 600;
}

.juno_speciality {
  position: relative;
  padding: 5em 0 0;
}
.juno_speciality .speciality_text {
  position: absolute;
  top: 0;
}
.juno_speciality .speciality_box {
  background: #fff;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 50px 50px 100px;
  position: relative;
}
.juno_speciality .speciality_box .speciality_img {
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.juno_speciality .speciality_box .speciality_img .speciality_featured {
  width: 530px;
  position: absolute;
  top: 15%;
  z-index: 9;
}
.juno_speciality .speciality_box .speciality_img .animation {
  position: absolute;
  width: 100px;
}
.juno_speciality .speciality_box .speciality_desc h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #213053;
}
.juno_speciality .speciality_box .speciality_desc h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #656565;
}
.juno_speciality .speciality_box .speciality_desc p {
  margin-bottom: 30px;
  color: #9db1bc;
}

.juno_key_highlights {
  padding: 5em 0 0;
  background: #fff;
}
.juno_key_highlights .heading h1 {
  font-size: 45px;
  font-weight: 800;
  color: rgb(52, 58, 64);
}
.juno_key_highlights .juno_key_box {
  background: #fff;
  box-shadow: 4px 4px 25px 1px rgba(0, 0, 0, 0.1215686275);
  border-radius: 23px;
  padding: 30px;
  margin-bottom: 35px;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  height: 85%;
}
.juno_key_highlights .juno_key_box:hover {
  background: #1139cb;
  color: #fff;
}
.juno_key_highlights .juno_key_box:hover .juno_img img {
  filter: brightness(1);
}
.juno_key_highlights .juno_key_box:hover .juno_desc p {
  color: #fff;
}
.juno_key_highlights .juno_key_box .juno_img {
  height: 50px;
  margin-bottom: 20px;
}
.juno_key_highlights .juno_key_box .juno_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.juno_key_highlights .juno_key_box .juno_img img {
  filter: brightness(0);
  width: auto;
}
.juno_key_highlights .juno_key_box .juno_desc h2 {
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}
.juno_key_highlights .juno_key_box .juno_desc p {
  font-size: 15px;
  line-height: 23px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
}

.juno_changemaking {
  padding: 5em 0;
  background: #fff url("../img/change_making_bg.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 130%;
}
.juno_changemaking .left_content {
  padding: 5em;
}
.juno_changemaking .left_content h2 {
  font-size: 50px;
  line-height: 60px;
  color: #1d396b;
  font-weight: 700;
}
.juno_changemaking .left_content p {
  font-size: 28px;
  color: #1d396b;
}
.juno_changemaking .juno_changemaking_box {
  background: #fff;
  padding: 30px;
  border-radius: 20px;
  margin-bottom: 40px;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 0px 10px 1px rgba(0, 0, 0, 0.05);
}
.juno_changemaking .juno_changemaking_box .image_box {
  height: 80px;
  width: 100%;
}
.juno_changemaking .juno_changemaking_box .image_box img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.juno_unique_character {
  padding: 5em 0 0;
}
.juno_unique_character .heading {
  text-align: center;
  margin-bottom: 50px;
}
.juno_unique_character .heading h1 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1d396b;
}
.juno_unique_character .heading p {
  font-size: 18px;
  line-height: 25px;
  color: #1d396b;
}
.juno_unique_character .juno_unique_box {
  text-align: center;
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border-radius: 16px;
  border: 2px solid #fff;
  height: 80%;
}
.juno_unique_character .juno_unique_box:hover {
  background: #1d396b;
}
.juno_unique_character .juno_unique_box:hover .juno_unique_desc h2 {
  color: #fff;
}
.juno_unique_character .juno_unique_box:hover .juno_unique_desc p {
  color: rgba(255, 255, 255, 0.7);
}
.juno_unique_character .juno_unique_box .juno_unique_icon {
  margin-bottom: 20px;
}
.juno_unique_character .juno_unique_box .juno_unique_desc h2 {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 700;
}
.juno_unique_character .juno_unique_box .juno_unique_desc p {
  color: #848484;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 25px;
  margin-bottom: 20px;
}

.juno_testimonial {
  padding: 5em 0 0;
}
.juno_testimonial .heading {
  text-align: center;
  margin-bottom: 80px;
}
.juno_testimonial .heading h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1d396b;
}
.juno_testimonial .heading p {
  font-size: 18px;
  line-height: 25px;
  color: #1d396b;
}
.juno_testimonial .testimonail_main_box {
  background: #213053;
}
.juno_testimonial .testimonail_main_box .swiper-button-next:after,
.juno_testimonial .testimonail_main_box .swiper-button-prev:after {
  color: #fff;
}
.juno_testimonial .testimonail_main_box .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #fff;
}
.juno_testimonial .testimonail_main_box .testimonial_box {
  padding: 50px 150px;
  color: #fff;
  position: relative;
}
.juno_testimonial .testimonail_main_box .testimonial_box .testimonial_image {
  position: relative;
  overflow: hidden;
  height: 280px;
}
.juno_testimonial .testimonail_main_box .testimonial_box .testimonial_image:before {
  content: "";
  position: absolute;
  top: 60px;
  left: 60px;
  width: 210px;
  height: 100%;
  background: #1d396b;
  border-radius: 15px;
  z-index: 2;
}
.juno_testimonial .testimonail_main_box .testimonial_box .testimonial_image:after {
  content: "";
  position: absolute;
  top: 0;
  right: 15px;
  width: 200px;
  height: 100%;
  background: url("../img//testimonial/text_bg.png") no-repeat 100%;
  z-index: 1;
  filter: invert(1);
}
.juno_testimonial .testimonail_main_box .testimonial_box .testimonial_image img {
  position: relative;
  z-index: 9;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.juno_testimonial .testimonail_main_box .testimonial_box .testimonial_content p {
  font-size: 16px;
  line-height: 36px;
  margin-bottom: 30px;
}
.juno_testimonial .testimonail_main_box .testimonial_box .testimonial_content .designation h4 {
  font-size: 14px;
}
.juno_testimonial .testimonail_main_box .testimonial_box .testimonial_content .designation p {
  opacity: 0.5;
  font-size: 13px;
  line-height: 25px;
}

.be_partner {
  padding: 5em 0;
}
.be_partner .wrap {
  background: #fff;
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 50px;
  position: relative;
}
.be_partner .wrap img {
  position: absolute;
  top: 0;
  right: 0;
}
.be_partner .wrap .partner_desc h1 {
  color: #1d396b;
  font-size: 44px;
  line-height: 58px;
  margin-bottom: 30px;
  font-weight: 700;
}
.be_partner .wrap .partner_desc P {
  font-size: 18px;
  color: rgba(29, 57, 107, 0.7);
  margin-bottom: 30px;
  font-weight: 700;
}

/*********************About Us********************************/
.team_consist {
  background: #fff;
  position: relative;
  padding: 3em 0;
}
.team_consist:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background: #1139cb;
  width: 70%;
  height: 100%;
  border-radius: 0 30px 30px 0;
}
.team_consist .container {
  position: relative;
}
.team_consist .container p {
  font-size: 20px;
  line-height: 45px;
  margin-bottom: 0;
  color: #fff;
}

.our_evolution {
  padding: 5em 0;
  background: #fff;
}
.our_evolution h1 {
  font-weight: 700;
  line-height: 88px;
  color: #213053;
  font-size: 45px;
}
.our_evolution p {
  color: rgba(51, 51, 51, 0.8);
  line-height: 30px;
  font-weight: 700;
}

.juno_campus_team {
  padding: 5em 0 0;
  background: #1139cb;
  position: relative;
}
.juno_campus_team .campus_team {
  position: absolute;
  width: 100%;
  max-width: 700px;
  bottom: 0;
}
.juno_campus_team .juno_campus_team_desc {
  padding: 20px;
  color: #fff;
}
.juno_campus_team .juno_campus_team_desc h1 {
  font-size: 38px;
  line-height: 50px;
  margin-bottom: 30px;
  font-weight: 700;
}
.juno_campus_team .juno_campus_team_desc ul {
  margin: 0;
  padding: 0;
}
.juno_campus_team .juno_campus_team_desc ul li {
  background: #fff;
  padding: 20px;
  list-style: none;
  margin-bottom: 20px;
  color: #000;
  border-radius: 20px;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  font-weight: 700;
}
.juno_campus_team .juno_campus_team_desc ul li:hover {
  background: #b1beee;
  margin-left: 5px;
}

.major_findings {
  padding: 5em 0;
  background: #fff;
}
.major_findings .heading {
  text-align: center;
  margin-bottom: 80px;
}
.major_findings .heading h1 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1d396b;
}
.major_findings .heading p {
  font-size: 20px;
  line-height: 25px;
  color: #1d396b;
}
.major_findings .specification {
  background: rgba(59, 71, 188, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 25px;
  padding: 30px;
  margin-top: 80px;
  position: relative;
}
.major_findings .specification:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 45%;
  width: 0.5px;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}
.major_findings .specification .circle_left,
.major_findings .specification .circle_right {
  position: absolute;
  width: 300px;
}
.major_findings .specification .circle_left {
  bottom: -5%;
  left: -15%;
}
.major_findings .specification .circle_right {
  top: -5%;
  right: -5%;
}
.major_findings .specification h1 {
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 40px;
}
.major_findings .specification h1.dark {
  color: rgba(0, 0, 0, 0.7);
}
.major_findings .specification h1.blue {
  color: #3c78f2;
}
.major_findings .specification .lists {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 15px;
  font-weight: 700;
}
.major_findings .specification .lists.dark {
  color: rgba(0, 0, 0, 0.7);
}
.major_findings .specification .lists.blue {
  color: #3c78f2;
}
.major_findings .specification .lists span {
  color: #4476bc;
  margin-right: 5px;
  font-weight: 600;
}

.about_steps {
  padding: 5em 0 1em;
}
.about_steps .timeline_box_container {
  background: url("../img/about_timeline/dots.png") no-repeat center;
}
.about_steps .timeline_box_container .timeline_box {
  background: #fff;
  box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.03);
  border-radius: 16px;
  padding: 30px;
  height: 300px;
  margin: 0 30px 100px;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border: 2px solid #fff;
}
.about_steps .timeline_box_container .timeline_box:hover {
  background: #1d396b;
}
.about_steps .timeline_box_container .timeline_box:hover .desc p {
  color: #fff;
}
.about_steps .timeline_box_container .timeline_box .icon {
  margin-bottom: 30px;
}
.about_steps .timeline_box_container .timeline_box .desc p {
  margin-bottom: 0;
  color: #848484;
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 30px;
  font-weight: 700;
}

.our_mission_vision {
  padding: 2em 0 3em;
}
.our_mission_vision .our_mission_vision_box {
  background: #fff;
  box-shadow: 3.65px 0.91px 26px 1px rgba(17, 57, 203, 0.12);
  border-radius: 27px;
  padding: 50px 0 0;
}
.our_mission_vision .our_mission_vision_box .desc h1 {
  font-weight: 700;
  font-size: 44px;
  line-height: 62px;
  color: #1d396b;
}
.our_mission_vision .our_mission_vision_box .desc p {
  margin-bottom: 30px;
  color: #1d396b;
  opacity: 0.8;
}
.our_mission_vision .our_mission_vision_box .desc p span {
  font-weight: 700;
  font-size: 22px;
  opacity: 1;
}

.our_values {
  padding: 5em 0;
}
.our_values .heading {
  text-align: center;
  margin-bottom: 40px;
}
.our_values .heading h1 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1d396b;
}
.our_values .heading p {
  font-size: 18px;
  line-height: 25px;
  color: #1d396b;
}
.our_values .value_box {
  text-align: center;
  padding: 30px;
  border-radius: 40px;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  background: #e3eeff;
  height: 100%;
}
.our_values .value_box:hover {
  filter: drop-shadow(0px 4px 35px rgba(0, 0, 0, 0.08));
}
.our_values .value_box:hover .featured_wrap {
  transform: translateY(-5%);
}
.our_values .value_box .featured_wrap {
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.our_values .value_box .icons {
  margin-bottom: 30px;
}
.our_values .value_box .icons img {
  width: 120px;
}
.our_values .value_box .desc {
  font-size: 20px;
  line-height: 25px;
  color: #1d396b;
  font-weight: 700;
}

/****** Teams ***********/
.education_solution_visionaries {
  background: #1139cb;
  padding: 5em 0 10em;
}
.education_solution_visionaries .heading {
  text-align: center;
  color: #fff;
}
.education_solution_visionaries .heading h1 {
  font-size: 54px;
  line-height: 64px;
  margin-bottom: 30px;
  font-weight: 700;
}
.education_solution_visionaries .heading p {
  font-size: 15px;
  margin-bottom: 30px;
}
.education_solution_visionaries .vision_box {
  position: relative;
}
.education_solution_visionaries .vision_box:hover .vision_desc {
  opacity: 1;
  visibility: visible;
}
.education_solution_visionaries .vision_box .vision_image {
  height: 350px;
  overflow: hidden;
}
.education_solution_visionaries .vision_box .vision_image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.education_solution_visionaries .vision_box .vision_desc {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #4f8def;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  color: #fff;
  opacity: 0;
  visibility: hidden;
}
.education_solution_visionaries .vision_box .vision_desc h4 {
  font-size: 34px;
  line-height: 32px;
  margin-bottom: 10px;
}
.education_solution_visionaries .vision_box .vision_desc p {
  font-size: 16px;
  line-height: 22px;
  opacity: 0.6;
}

.the_dream_team {
  background: #fff;
  padding: 6em 0 0;
  position: relative;
}
.the_dream_team:before, .the_dream_team:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  background-size: 100%;
}
.the_dream_team:before {
  top: -35px;
  background: url("../img/bg_top.png") no-repeat;
}
.the_dream_team .heading {
  text-align: center;
  color: #283646;
}
.the_dream_team .heading h1 {
  font-size: 54px;
  line-height: 64px;
  margin-bottom: 30px;
  font-weight: 800;
}
.the_dream_team .heading p {
  font-size: 15px;
  margin-bottom: 40px;
  font-weight: 700;
}
.the_dream_team .team_box {
  margin-bottom: 80px;
  text-align: center;
}
.the_dream_team .team_box .team_profile {
  width: 150px;
  height: 150px;
  overflow: hidden;
  border-radius: 50%;
  margin: auto;
  margin-bottom: 40px;
}
.the_dream_team .team_box .team_profile img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.the_dream_team .team_box .team_desc h3 {
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #283646;
  font-weight: 700;
}
.the_dream_team .team_box .team_desc p {
  font-size: 16px;
  line-height: 150%;
  color: #77808b;
}

/********* Media *********/
.annual_celebration {
  padding: 5em 0;
  background: #fff;
}
.annual_celebration .heading {
  color: #213053;
}
.annual_celebration .heading h1 {
  font-size: 54px;
  line-height: 64px;
  margin-bottom: 30px;
  font-weight: 700;
}
.annual_celebration .heading p {
  font-size: 20px;
  margin-bottom: 40px;
  font-weight: 600;
}
.annual_celebration .all_events {
  display: grid;
  grid-gap: 10px;
  grid-auto-flow: dense;
  grid-template-columns: repeat(auto-fill, minmax(136px, 2fr));
  grid-auto-rows: minmax(120px, auto);
  position: relative;
}
.annual_celebration .all_events .left,
.annual_celebration .all_events .right {
  position: absolute;
}
.annual_celebration .all_events .left {
  top: 0;
}
.annual_celebration .all_events .right {
  bottom: 0;
  right: 0;
}
.annual_celebration .all_events .hide {
  width: 100%;
  height: 100%;
}
.annual_celebration .all_events .hide.ratio2x2 {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
}
.annual_celebration .all_events .evens_grid {
  position: relative;
  height: 100%;
}
.annual_celebration .all_events .evens_grid img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.annual_celebration .all_events .evens_grid.ratio2x1 {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}
.annual_celebration .all_events .evens_grid.ratio2x2 {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
}
.annual_celebration .all_events .evens_grid.ratio2x4 {
  -ms-grid-column-span: 2;
  grid-column-end: span 2;
  -ms-grid-row-span: 4;
  grid-row-end: span 4;
}
.annual_celebration .all_events .evens_grid.ratio4x2 {
  -ms-grid-column-span: 4;
  grid-column-end: span 4;
  -ms-grid-row-span: 2;
  grid-row-end: span 2;
}

.news_and_videos {
  padding: 4em 0 2em;
  background: #fff;
}
.news_and_videos .heading {
  color: #213053;
}
.news_and_videos .heading h1 {
  font-size: 45px;
  line-height: 64px;
  margin-bottom: 30px;
  font-weight: 700;
}
.news_and_videos .news_image {
  height: 250px;
  overflow: hidden;
  margin-bottom: 30px;
}
.news_and_videos .news_image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

/*************** Why Juno Campus ******************/
.source_truth {
  padding: 5em 0;
}
.source_truth .wrap {
  background: #fff;
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 10px 50px;
}
.source_truth .wrap .partner_desc h1 {
  color: #1d396b;
  font-size: 47px;
  line-height: 58px;
  margin-bottom: 30px;
  font-weight: 700;
}
.source_truth .wrap .partner_desc P {
  font-size: 20px;
  color: rgba(29, 57, 107, 0.7);
  margin-bottom: 30px;
  line-height: 35px;
}

.built_to_adapt {
  padding: 2em 0 3em;
  position: relative;
}
.built_to_adapt::before {
  content: "";
  position: absolute;
  bottom: -5%;
  width: 100%;
  height: 50px;
  background-size: 100%;
  background: url("../img/bg_top.png") no-repeat;
  z-index: 1;
}
.built_to_adapt .img-fluid {
  position: absolute;
  bottom: 0;
  width: 600px;
  z-index: 2;
}
.built_to_adapt .desc h1 {
  font-size: 48px;
  line-height: 88px;
  color: #213053;
  margin-bottom: 20px;
  font-weight: 700;
}
.built_to_adapt .desc p {
  font-size: 20px;
  line-height: 26px;
  color: #213053;
}

.multi_level_domain {
  padding: 5em 0;
  background: #fff;
}
.multi_level_domain .heading {
  text-align: center;
  margin-bottom: 50px;
}
.multi_level_domain .heading h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1d396b;
}
.multi_level_domain .heading p {
  font-size: 18px;
  line-height: 25px;
  color: #1d396b;
}
.multi_level_domain .multi_level_domain_tab ul {
  margin: 0;
  padding: 0;
}
.multi_level_domain .multi_level_domain_tab ul li {
  background: #fff;
  padding: 15px 20px;
  color: #000;
  list-style: none;
  box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin-bottom: 20px;
}
.multi_level_domain .multi_level_box {
  background: #ffffff;
  box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 25px 15px;
  text-align: center;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin: 20px;
}
.multi_level_domain .multi_level_box:hover {
  background: #1d396b;
}
.multi_level_domain .multi_level_box:hover .multi_level_title {
  color: #fff;
}
.multi_level_domain .multi_level_box:hover .multi_level_icon {
  filter: brightness(0) invert(1);
}
.multi_level_domain .multi_level_box.space {
  margin-top: 50px;
}
.multi_level_domain .multi_level_box .multi_level_icon {
  margin-bottom: 10px;
}
.multi_level_domain .multi_level_box .multi_level_title {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #1d396b;
}

.getwell_other {
  padding: 5em 0;
}
.getwell_other .heading {
  text-align: center;
  background: url("../img/get_well_bg.png") no-repeat center;
  background-size: 100%;
  padding: 20px;
  margin-bottom: 5em;
}
.getwell_other .heading h1 {
  font-size: 50px;
  font-weight: 700;
  color: #213053;
  margin-bottom: 30px;
}
.getwell_other .heading p {
  color: rgba(29, 57, 107, 0.7);
  font-weight: 700;
}
.getwell_other .security_list {
  margin: 0;
  padding: 0;
  display: flex;
}
.getwell_other .security_list li {
  background: #ffffff;
  box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 20px;
  list-style: none;
  text-align: center;
  width: 16.6666666667%;
  margin: 15px;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border: 2px solid transparent;
}
.getwell_other .security_list li:hover {
  transform: translateY(-5%);
  border: 2px solid #699eee;
}
.getwell_other .security_list li .security_list_icon {
  margin-bottom: 25px;
}
.getwell_other .security_list li .security_list_title {
  font-size: 14px;
  line-height: 19px;
  color: #213053;
  font-weight: 700;
}

.features {
  padding: 5em 0;
  background: url("../img/features_bg.png") no-repeat left center;
  background-size: 90% 100%;
}
.features .heading {
  text-align: center;
  margin-bottom: 80px;
}
.features .heading h1 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1d396b;
}
.features .heading p {
  font-size: 18px;
  line-height: 25px;
  color: #1d396b;
}
.features .heading p {
  font-weight: 700;
}
.features .feature_box {
  background: #fff;
  box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 60px 30px;
  height: 480px;
  position: relative;
  margin-bottom: 30px;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.features .feature_box:hover {
  background: rgba(239, 246, 255, 0.5);
}
.features .feature_box .icons {
  position: absolute;
  top: 20px;
  right: 20px;
}
.features .feature_box .icons img {
  width: 140px;
}
.features .feature_box h1 {
  font-size: 28px;
  line-height: 34px;
  color: #213053;
  margin-bottom: 40px;
  font-weight: 800;
}
.features .feature_box p {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0;
  font-weight: 700;
}

/************* offer_banner *****************/
.offer_banner {
  background: #fff;
  position: relative;
  padding: 4em 0;
}
.offer_banner img.feature {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 9;
  transform: translate(-50%, -2%);
}
.offer_banner:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: -5%;
  background: url("../img/bg_bottom.png") no-repeat;
  background-size: 100%;
  z-index: 1;
}

.offer_feature {
  padding: 5em 0;
  position: relative;
}
.offer_feature:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: -14px;
  background: url("../img/bg_top.png") no-repeat;
  background-size: 100%;
  z-index: 1;
}
.offer_feature .feature_box {
  background: #ffffff;
  box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  padding: 30px 20px;
  margin-bottom: 30px;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.offer_feature .feature_box:hover {
  background: #213053;
}
.offer_feature .feature_box:hover h1 {
  color: #fff;
}
.offer_feature .feature_box:hover ul li {
  color: #fff;
}
.offer_feature .feature_box h1 {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #213053;
  font-weight: 700;
  margin-bottom: 20px;
}
.offer_feature .feature_box ul {
  margin: 0;
  padding: 0;
}
.offer_feature .feature_box ul li {
  list-style-type: none;
  margin-bottom: 5px;
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
}

.student_lyfcycle {
  background: #fff;
  position: relative;
  padding: 0 0 3em;
}
.student_lyfcycle .student_lyfcycle_process {
  background: url("../img/right_arrow.png") no-repeat center center;
  background-size: 80%;
  padding: 30px;
  margin: 80px 0;
}
.student_lyfcycle .student_lyfcycle_process ul {
  margin: 0;
  padding: 0;
  display: flex;
}
.student_lyfcycle .student_lyfcycle_process ul li {
  list-style: none;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.student_lyfcycle .student_lyfcycle_process ul li:hover {
  transform: translateY(-5%);
}
.student_lyfcycle .heading {
  text-align: center;
  margin-bottom: 50px;
}
.student_lyfcycle .heading h2 {
  margin-top: 20px;
}
.student_lyfcycle .heading h1,
.student_lyfcycle .heading h2 {
  font-size: 44px;
  line-height: 53px;
  color: #213053;
  font-weight: 700;
}
.student_lyfcycle .heading .line {
  width: 50%;
  height: 2px;
  border: 3px solid #064b72;
  margin: auto;
}
.student_lyfcycle .row .col-lg-4:nth-child(1) .workflow {
  background: #eff6ff;
}
.student_lyfcycle .row .col-lg-4:nth-child(2) .workflow {
  background: #dbeef4;
}
.student_lyfcycle .row .col-lg-4:nth-child(3) .workflow {
  background: #e3f8ff;
}
.student_lyfcycle .row .col-lg-4:nth-child(4) .workflow {
  background: #dbeef4;
}
.student_lyfcycle .row .col-lg-4:nth-child(5) .workflow {
  background: #b9cde5;
}
.student_lyfcycle .row .col-lg-4:nth-child(6) .workflow {
  background: #8eb4e3;
}
.student_lyfcycle .workflow {
  padding: 30px;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-bottom: 30px;
  border-radius: 30px;
  box-shadow: 3.65px 0.91px 26px 1px rgba(0, 0, 0, 0.1);
  height: 330px;
  position: relative;
  margin: 15px;
}
.student_lyfcycle .workflow:hover {
  background: #fff !important;
}
.student_lyfcycle .workflow h1 {
  font-size: 28px;
  line-height: 34px;
  color: #1d396b;
  font-weight: 700;
  margin-bottom: 20px;
}
.student_lyfcycle .workflow p {
  font-size: 16px;
  line-height: 30px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.7);
}
.student_lyfcycle .workflow .dotted {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, 0);
}

.success_story {
  padding: 5em 0 2em;
  background: #fff;
}
.success_story .heading h2 {
  font-weight: 700;
  margin-bottom: 40px;
  font-size: 40px;
  color: #1d396b;
}
.success_story .swiper-button-next {
  right: 0;
}
.success_story .swiper-button-prev {
  left: 0;
}
.success_story .swiper-button-next,
.success_story .swiper-button-prev {
  color: #213053;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #1d396b;
}
.success_story .swiper-button-next:after,
.success_story .swiper-button-prev:after {
  font-size: 14px;
  color: #fff;
}
.success_story .wraped {
  background: #ffffff;
  box-shadow: 3.65px 0.91px 26px 1px #d0e7f7;
  border-radius: 20px;
  padding: 40px;
  margin: 30px;
  height: 650px;
}
.success_story .wraped .desc .icon_box {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.success_story .wraped .desc .icon_box .icon {
  margin-right: 30px;
}
.success_story .wraped .desc .icon_box .icon img {
  width: 70px;
}
.success_story .wraped .desc .name h3 {
  font-size: 24px;
  color: #213053;
  font-weight: 700;
}
.success_story .wraped .desc .name p {
  font-size: 15px;
  color: rgba(33, 48, 83, 0.7);
  margin-bottom: 0;
}
.success_story .wraped .desc h1 {
  font-size: 32px;
  line-height: 75px;
  color: #213053;
  font-weight: 700;
}
.success_story .wraped .desc h4 {
  color: #213053;
  font-weight: 700;
}
.success_story .wraped .desc p {
  font-size: 16px;
  text-align: justify;
  color: rgba(33, 48, 83, 0.7);
  margin-bottom: 20px;
}
.success_story .wraped .desc ul {
  margin: 0;
  padding: 0;
}
.success_story .wraped .desc ul li {
  list-style: none;
  position: relative;
  margin-bottom: 10px;
  font-size: 16px;
  color: rgba(33, 48, 83, 0.7);
  padding-left: 40px;
}
.success_story .wraped .desc ul li:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  background: #213053;
  border-radius: 50%;
}

.proud_customer {
  padding: 3em 0 0;
  background: #fff;
  position: relative;
}
.proud_customer .heading {
  text-align: center;
  margin-bottom: 50px;
}
.proud_customer .heading h1 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1d396b;
}
.proud_customer .heading p {
  font-size: 20px;
  line-height: 25px;
  color: #1d396b;
}
.proud_customer .heading p {
  font-weight: 700;
}
.proud_customer:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 50px;
  background-size: 100%;
}
.proud_customer:after {
  bottom: -4%;
  background: url("../img/bg_bottom.png") no-repeat;
  z-index: 1;
}

.proud_customer_list {
  padding: 8em 0;
  position: relative;
}
.proud_customer_list .list_box {
  margin-bottom: 40px;
}
.proud_customer_list .list_box .list_box_img {
  background: #ffffff;
  box-shadow: 3.65px 0.91px 26px 1px #d0e7f7;
  border-radius: 10px;
  padding: 30px;
  text-align: center;
  margin-bottom: 20px;
  height: 200px;
  overflow: hidden;
}
.proud_customer_list .list_box .list_box_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.proud_customer_list .list_box .heading h1 {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  font-weight: 700;
}

.contact_form {
  padding: 5em 0;
  background: #fff;
}
.contact_form .heading {
  text-align: center;
  margin-bottom: 50px;
}
.contact_form .heading h1 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #1d396b;
}
.contact_form .heading p {
  font-size: 20px;
  line-height: 25px;
  color: #1d396b;
}
.contact_form .form_wrap {
  background: #ffffff;
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: 50px 30px;
}
.contact_form .form_wrap .form-floating {
  margin-bottom: 30px;
}
.contact_form .form_wrap .form-floating label {
  font-weight: 700;
}
.contact_form .form_wrap .form-floating input,
.contact_form .form_wrap .form-floating select,
.contact_form .form_wrap .form-floating textarea {
  background: #e0eeff;
  border: 0;
}

footer {
  background-color: #1d396b;
  padding: 5em 0;
  position: relative;
  overflow: hidden;
  font-weight: 500;
}
footer .footer_left_img,
footer .footer_top_img {
  position: absolute;
}
footer .footer_left_img {
  left: 0;
  top: 50%;
  width: 150px;
  transform: translate(0, -50%);
}
footer .footer_top_img {
  right: 5%;
  top: 0;
  width: 300px;
  transform: translate(0, -25%);
}
footer .newsletter {
  padding-bottom: 40px;
  margin-bottom: 40px;
  border-bottom: 1px solid #ffffff;
}
footer .newsletter .newsletter_content {
  color: #fff;
}
footer .newsletter .newsletter_content h1 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
}
footer .newsletter .newsletter_content p {
  color: #fefeff;
  font-size: 16px;
  line-height: 28px;
  opacity: 0.8;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}
footer .newsletter .newsletter_form_box .newsletter_form {
  position: relative;
}
footer .newsletter .newsletter_form_box .newsletter_form input {
  width: 100%;
  height: 66px;
  border: 1px solid #b4b6c4;
  border-radius: 20px;
}
footer .newsletter .newsletter_form_box .newsletter_form button {
  position: absolute;
  top: 6px;
  right: 6px;
}
footer .address_box {
  display: flex;
}
footer .address_box .address {
  padding-left: 15px;
  color: #fff;
}
footer .address_box .address h4 {
  font-size: 16px;
  line-height: 26px;
}
footer .address_box .address p {
  font-size: 14px;
  line-height: 26px;
  opacity: 0.8;
  font-weight: 400;
  color: #b4b6c4;
  letter-spacing: 0.5px;
}
footer .useful_links {
  color: #fefeff;
}
footer .useful_links h3 {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
}
footer .useful_links a {
  display: block;
  color: #b4b6c4;
  font-size: 14px;
  margin-bottom: 10px;
  text-decoration: none;
  opacity: 0.8;
  letter-spacing: 0.5px;
}
footer .useful_links .social_links {
  display: flex;
}
footer .useful_links .social_links a {
  margin-right: 10px;
}

.copyright {
  padding: 2em 0;
  background-color: #060c3c;
  text-align: center;
}
.copyright p {
  margin-bottom: 0;
  color: #fff;
  letter-spacing: 0.5px;
}/*# sourceMappingURL=style.css.map */