@media (min-width: 320px) and (max-width: 767px) {
  .navbar {
    z-index: 99;
  }
  .navbar .navbar-brand img {
    width: 45px;
  }
  .navbar .navbar-brand h2{
    font-size: 11px;
  }
  .navbar .navbar-collapse {
    background: #fff;
    padding: 25px 0;
  }
  .navbar .navbar-collapse .navbar-nav {
    gap: 10px;
    align-items: flex-start;
  }
  .juno_banner {
    padding: 15em 0 0;
  }
  .juno_banner.mob {
    padding: 15em 0 5em;
  }
  .juno_banner .juno_banner_bg {
    height: auto;
    top: 15%;
    left: -55px;
  }
  .juno_banner .caption {
    margin-top: 82px;
    text-align: center;
  }
  .juno_banner .caption .caption_text {
    margin-bottom: 10px;
  }
  .juno_banner .caption .caption_text h1 {
    font-size: 25px;
    margin-bottom: 15px;
  }
  .juno_banner .caption .caption_text h2 {
    font-size: 21px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  .juno_banner .caption .caption_text p {
    font-size: 15px;
    margin-bottom: 0px;
  }
  .featured_all {
    padding: 4em 0 2em;
  }
  .featured_all .featured_box {
    padding: 40px 30px;
    height: auto;
  }
  .featured_all .featured_box .featured_img img {
    width: 110px;
  }
  .juno_speciality {
    padding: 2em 0 0;
  }
  .juno_speciality .speciality_box {
    padding: 40px;
  }
  .juno_speciality .speciality_box .speciality_img .animating_img {
    display: none;
  }
  .juno_speciality .speciality_box .speciality_img .speciality_featured {
    position: relative;
    margin-bottom: 50px;
  }
  .juno_speciality .speciality_box .speciality_desc h1 {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .juno_speciality .speciality_box .speciality_desc h4 {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .juno_speciality .speciality_box .speciality_desc p {
    font-size: 15px;
  }
  .juno_key_highlights img.dotted_design {
    width: 100px;
  }
  .juno_key_highlights .heading {
    text-align: center;
  }
  .juno_key_highlights .heading h1 {
    font-size: 35px;
  }
  .juno_changemaking {
    background-size: 1000px 80%;
    background-position: center right;
    padding: 2em 0;
  }
  .juno_changemaking .left_content {
    padding: 20px;
  }
  .juno_changemaking .left_content h2 {
    font-size: 33px;
    line-height: 40px;
  }
  .juno_changemaking .left_content p {
    opacity: 0.5;
  }
  .juno_changemaking .partners_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .juno_changemaking .partners_list .juno_changemaking_box {
    width: 45%;
    margin-bottom: 20px;
  }
  .juno_unique_character .heading h1 {
    font-size: 25px;
    margin-bottom: 5px;
  }
  .juno_unique_character .heading p {
    font-size: 15px;
  }
  .juno_unique_character .juno_unique_box {
    height: auto;
  }
  .juno_testimonial {
    padding: 3em 0 0;
  }
  .juno_testimonial .heading {
    text-align: center;
  }
  .juno_testimonial .heading h1 {
    font-size: 35px;
  }
  .juno_testimonial .swiper-button-prev,
  .juno_testimonial .swiper-button-next {
    display: none;
  }
  .juno_testimonial .swiper-pagination {
    bottom: 35px !important;
  }
  .juno_testimonial .testimonail_main_box .testimonial_box {
    padding: 40px;
  }
  .juno_testimonial .testimonail_main_box .testimonial_box .testimonial_image {
    height: 180px;
    margin-bottom: 40px;
  }
  .juno_testimonial .testimonail_main_box .testimonial_box .testimonial_image:before {
    width: 190px;
    left: 35px;
  }
  .juno_testimonial .testimonail_main_box .testimonial_box .testimonial_image:after {
    width: 130px;
    right: 45px;
    height: 70%;
  }
  .juno_testimonial .testimonail_main_box .testimonial_box .testimonial_content p {
    font-size: 13px;
    line-height: 25px;
  }
  .be_partner .wrap {
    padding: 25px;
  }
  .be_partner .wrap .partner_desc h1 {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
  }
  footer .footer_left_img {
    display: none;
  }
  footer .footer_top_img {
    width: 100px;
    transform: translate(0, -40%);
  }
  footer .newsletter .newsletter_content {
    margin-bottom: 25px;
  }
  footer .newsletter .newsletter_content h1 {
    font-size: 28px;
    font-weight: 600;
  }
  .juno_common_banner {
    padding: 8em 0 3em;
  }
  .juno_common_banner img {
    margin-bottom: 30px;
  }
  .juno_common_banner .container .caption_text {
    text-align: center;
  }
  .juno_common_banner .container .caption_text h1 {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 18px;
  }
  .juno_common_banner .container .caption_action {
    text-align: center;
  }
  .team_consist:before {
    width: 100%;
    border-radius: 0;
  }
  .team_consist .container p {
    font-size: 15px;
    line-height: 30px;
    text-align: center;
  }
  .our_evolution {
    padding: 5em 0 3em;
  }
  .our_evolution img {
    margin-bottom: 30px;
  }
  .juno_campus_team {
    padding: 3em 0 0;
  }
  .juno_campus_team .campus_team {
    position: relative;
  }
  .juno_campus_team .juno_campus_team_desc h1 {
    font-size: 25px;
    line-height: 40px;
    text-align: center;
  }
  .juno_campus_team .juno_campus_team_desc ul li {
    font-size: 15px;
  }
  .major_findings {
    padding: 3em 0;
  }
  .major_findings .heading h1 {
    font-size: 30px;
    text-align: center;
  }
  .major_findings .specification:before {
    display: none;
  }
  .major_findings .specification h1 {
    font-size: 20px;
    line-height: 40px;
    font-weight: 600;
    margin: 15px 0;
  }
  .major_findings .specification .lists {
    font-size: 15px;
  }
  .about_steps .timeline_box_container {
    background: transparent;
  }
  .about_steps .timeline_box_container .row .col-lg-4:nth-child(4) {
    order: 6;
  }
  .about_steps .timeline_box_container .row .col-lg-4:nth-child(5) {
    order: 5;
  }
  .about_steps .timeline_box_container .row .col-lg-4:nth-child(6) {
    order: 4;
  }
  .about_steps .timeline_box_container .timeline_box {
    height: auto;
    margin: 15px 30px;
  }
  .our_mission_vision .our_mission_vision_box {
    padding: 20px 20px 40px;
  }
  .our_mission_vision .our_mission_vision_box img {
    margin-bottom: 40px;
  }
  .our_mission_vision .our_mission_vision_box .desc h1 {
    font-size: 28px;
    text-align: center;
  }
  .education_solution_visionaries {
    padding: 3em 0;
  }
  .education_solution_visionaries .heading h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .education_solution_visionaries .heading p {
    font-size: 18px;
  }
  .education_solution_visionaries .vision_box {
    margin-bottom: 30px;
  }
  .the_dream_team {
    padding: 3em 0 0;
  }
  .the_dream_team .heading h1 {
    font-size: 35px;
    margin-bottom: 0px;
  }
  .the_dream_team .team_box {
    margin-bottom: 40px;
  }
  .annual_celebration {
    padding: 1em 0;
  }
  .annual_celebration .heading {
    text-align: center;
  }
  .annual_celebration .heading h1 {
    font-size: 25px;
    line-height: 10px;
  }
  .annual_celebration .all_events {
    grid-template-columns: repeat(auto-fill, minmax(70px, 2fr));
    grid-auto-rows: minmax(50px, auto);
  }
  .annual_celebration .all_events .left,
  .annual_celebration .all_events .right,
  .annual_celebration .all_events .hide {
    display: none;
  }
  .source_truth {
    padding-bottom: 2em;
  }
  .source_truth .wrap {
    padding: 40px 25px;
  }
  .source_truth .wrap img {
    margin-bottom: 30px;
  }
  .source_truth .wrap .partner_desc {
    text-align: center;
  }
  .source_truth .wrap .partner_desc h1 {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 10px;
  }
  .source_truth .wrap .partner_desc p {
    font-size: 15px;
  }
  .built_to_adapt .img-fluid {
    position: relative;
    margin-bottom: 40px;
  }
  .built_to_adapt .desc {
    text-align: center;
  }
  .built_to_adapt .desc h1 {
    font-size: 35px;
    line-height: 20px;
    font-weight: 600;
  }
  .multi_level_domain {
    padding: 3em 0;
  }
  .multi_level_domain .heading h1 {
    font-size: 27px;
  }
  .multi_level_domain .multi_level_box {
    margin: 10px 0;
  }
  .multi_level_domain .multi_level_box.space {
    margin-top: 10px;
  }
  .getwell_other {
    padding: 3em 0 3em;
  }
  .getwell_other .heading {
    margin-bottom: 0;
  }
  .getwell_other .heading h1 {
    font-size: 38px;
  }
  .getwell_other .security_list {
    flex-wrap: wrap;
  }
  .getwell_other .security_list li {
    width: 43%;
    margin: 10px;
  }
  .features {
    padding: 3em 0;
  }
  .features .feature_box {
    height: auto;
  }
  .features .feature_box .icons img {
    width: 80px;
  }
  .features .feature_box h1 {
    font-size: 23px;
    line-height: 35px;
    margin-bottom: 15px;
  }
  .features .feature_box p {
    font-size: 13px;
    line-height: 25px;
  }
  .offer_banner img.feature {
    width: 300px;
  }
  .offer_banner:after {
    bottom: -15%;
  }
  .offer_feature {
    padding: 5em 0 3em;
  }
  .offer_feature:after {
    bottom: -42px;
  }
  .offer_feature .feature_box {
    text-align: center;
  }
  .student_lyfcycle .student_lyfcycle_process {
    margin: 30px 0;
    background: transparent;
  }
  .student_lyfcycle .student_lyfcycle_process ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .student_lyfcycle .student_lyfcycle_process ul li {
    width: 30%;
  }
  .student_lyfcycle .heading h1,
  .student_lyfcycle .heading h2 {
    font-size: 20px;
    line-height: 25px;
  }
  .student_lyfcycle .workflow {
    height: auto;
  }
  .student_lyfcycle .workflow h1 {
    font-size: 22px;
    line-height: 30px;
  }
  .student_lyfcycle .workflow img.dotted {
    width: 150px;
    bottom: 8%;
  }
  .success_story {
    padding: 3em 0 2em;
  }
  .success_story .heading h2 {
    text-align: center;
    font-size: 30px;
  }
  .success_story .wraped {
    height: auto;
  }
  .success_story .wraped .desc h1 {
    font-size: 23px;
  }
  .success_story .wraped .desc .icon_box {
    flex-direction: column;
    gap: 20px;
  }
  .success_story .wraped .desc .icon_box .icon {
    margin-right: 0;
  }
  .success_story .wraped .desc .name {
    text-align: center;
  }
  .success_story .wraped .desc .name h3 {
    font-size: 20px;
  }
  .success_story .wraped .desc p {
    font-size: 13px;
    text-align: center;
  }
  .proud_customer:after {
    bottom: -9%;
  }
  .proud_customer .heading h1 {
    font-size: 25px;
  }
  .proud_customer_list {
    padding: 8em 0 1em;
  }
  .proud_customer_list .list_box {
    margin-bottom: 0;
  }
  .contact_form {
    padding: 0em 0 4em;
  }
}/*# sourceMappingURL=responsive.css.map */