@media (min-width: 320px) and (max-width: 767px) {
  .navbar {
    z-index: 99;
    .navbar-brand {
      img {
        width: 200px;
      }
    }
    .navbar-collapse {
      background: #fff;
      padding: 25px 0;
      .navbar-nav {
        gap: 10px;
        align-items: flex-start;
      }
    }
  }

  //juno_banner
  .juno_banner {
    padding: 15em 0 0;
    &.mob {
      padding: 15em 0 5em;
    }
    .juno_banner_bg {
      height: auto;
      top: 15%;
      left: -55px;
    }
    .caption {
      margin-top: 82px;
      text-align: center;
      .caption_text {
        margin-bottom: 10px;
        h1 {
          font-size: 25px;
          margin-bottom: 15px;
        }
        h2 {
          font-size: 21px;
          line-height: 28px;
          margin-bottom: 15px;
        }
        p {
          font-size: 15px;
          margin-bottom: 0px;
        }
      }
    }
  }

  //featured_all
  .featured_all {
    padding: 4em 0 2em;
    .featured_box {
      padding: 40px 30px;
      height: auto;
      .featured_img {
        img {
          width: 110px;
        }
      }
    }
  }

  //juno_speciality
  .juno_speciality {
    padding: 2em 0 0;
    .speciality_box {
      padding: 40px;
      .speciality_img {
        .animating_img {
          display: none;
        }
        .speciality_featured {
          position: relative;
          margin-bottom: 50px;
        }
      }
      .speciality_desc {
        h1 {
          font-size: 22px;
          margin-bottom: 15px;
        }
        h4 {
          font-size: 14px;
          margin-bottom: 15px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }

  //juno_key_highlights
  .juno_key_highlights {
    img.dotted_design {
      width: 100px;
    }
    .heading {
      text-align: center;
      h1 {
        font-size: 35px;
      }
    }
  }
  .juno_changemaking {
    background-size: 1000px 80%;
    background-position: center right;
    padding: 2em 0;
    .left_content {
      padding: 20px;
      h2 {
        font-size: 33px;
        line-height: 40px;
      }
      p {
        opacity: 0.5;
      }
    }
    .partners_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      .juno_changemaking_box {
        width: calc(100% / 2)-5%;
        margin-bottom: 20px;
      }
    }
  }

  //juno_unique_character
  .juno_unique_character {
    .heading {
      h1 {
        font-size: 25px;
        margin-bottom: 5px;
      }
      p {
        font-size: 15px;
      }
    }
    .juno_unique_box {
      height: auto;
    }
  }

  //testimonial
  .juno_testimonial {
    padding: 3em 0 0;
    .heading {
      text-align: center;
      h1 {
        font-size: 35px;
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
    .swiper-pagination {
      bottom: 35px !important;
    }
    .testimonail_main_box {
      .testimonial_box {
        padding: 40px;
        .testimonial_image {
          height: 180px;
          margin-bottom: 40px;
          &:before {
            width: 190px;
            left: 35px;
          }
          &:after {
            width: 130px;
            right: 45px;
            height: 70%;
          }
        }
        .testimonial_content {
          p {
            font-size: 13px;
            line-height: 25px;
          }
        }
      }
    }
  }

  //be_partner
  .be_partner {
    .wrap {
      padding: 25px;
      .partner_desc {
        h1 {
          font-size: 30px;
          line-height: 40px;
          font-weight: 600;
        }
      }
    }
  }

  //footer
  footer {
    .footer_left_img {
      display: none;
    }
    .footer_top_img {
      width: 100px;
      transform: translate(0, -40%);
    }
    .newsletter {
      .newsletter_content {
        margin-bottom: 25px;
        h1 {
          font-size: 28px;
          font-weight: 600;
        }
      }
    }
  }

  //juno_common_banner
  .juno_common_banner {
    padding: 8em 0 3em;
    img {
      margin-bottom: 30px;
    }
    .container {
      .caption_text {
        text-align: center;
        h1 {
          font-size: 30px;
          font-weight: 600;
          margin-bottom: 18px;
        }
      }
      .caption_action {
        text-align: center;
      }
    }
  }
  .team_consist {
    &:before {
      width: 100%;
      border-radius: 0;
    }
    .container {
      p {
        font-size: 15px;
        line-height: 30px;
        text-align: center;
      }
    }
  }

  //our_evolution
  .our_evolution {
    padding: 5em 0 3em;
    img {
      margin-bottom: 30px;
    }
  }
  //juno_campus_team
  .juno_campus_team {
    padding: 3em 0 0;
    .campus_team {
      position: relative;
    }
    .juno_campus_team_desc {
      h1 {
        font-size: 25px;
        line-height: 40px;
        text-align: center;
      }
      ul {
        li {
          font-size: 15px;
        }
      }
    }
  }

  //major_findings
  .major_findings {
    padding: 3em 0;
    .heading {
      h1 {
        font-size: 30px;
        text-align: center;
      }
    }
    .specification {
      &:before {
        display: none;
      }
      h1 {
        font-size: 20px;
        line-height: 40px;
        font-weight: 600;
        margin: 15px 0;
      }
      .lists {
        font-size: 15px;
      }
    }
  }

  //about_steps
  .about_steps {
    .timeline_box_container {
      background: transparent;
      .row {
        .col-lg-4 {
          &:nth-child(4) {
            order: 6;
          }
          &:nth-child(5) {
            order: 5;
          }
          &:nth-child(6) {
            order: 4;
          }
        }
      }
      .timeline_box {
        height: auto;
        margin: 15px 30px;
      }
    }
  }

  //our_mission_vision
  .our_mission_vision {
    .our_mission_vision_box {
      padding: 20px 20px 40px;
      img {
        margin-bottom: 40px;
      }
      .desc {
        h1 {
          font-size: 28px;
          text-align: center;
        }
      }
    }
  }

  //education_solution_visionaries
  .education_solution_visionaries {
    padding: 3em 0;
    .heading {
      h1 {
        font-size: 30px;
        line-height: 40px;
      }
      p {
        font-size: 18px;
      }
    }
    .vision_box {
      margin-bottom: 30px;
    }
  }

  //the_dream_team
  .the_dream_team {
    padding: 3em 0 0;
    .heading {
      h1 {
        font-size: 35px;
        margin-bottom: 0px;
      }
    }
    .team_box {
      margin-bottom: 40px;
    }
  }

  //annual_celebration
  .annual_celebration {
    padding: 1em 0;
    .heading {
      text-align: center;
      h1 {
        font-size: 25px;
        line-height: 10px;
      }
    }
    .all_events {
      grid-template-columns: repeat(auto-fill, minmax(70px, 2fr));
      grid-auto-rows: minmax(50px, auto);
      .left,
      .right,
      .hide {
        display: none;
      }
    }
  }

  //source_truth
  .source_truth {
    padding-bottom: 2em;
    .wrap {
      padding: 40px 25px;
      img {
        margin-bottom: 30px;
      }
      .partner_desc {
        text-align: center;
        h1 {
          font-size: 32px;
          line-height: 45px;
          margin-bottom: 10px;
        }
        p {
          font-size: 15px;
        }
      }
    }
  }

  //built_to_adapt
  .built_to_adapt {
    .img-fluid {
      position: relative;
      margin-bottom: 40px;
    }
    .desc {
      text-align: center;
      h1 {
        font-size: 35px;
        line-height: 20px;
        font-weight: 600;
      }
    }
  }

  //multi_level_domain
  .multi_level_domain {
    padding: 3em 0;
    .heading {
      h1 {
        font-size: 27px;
      }
    }
    .multi_level_box {
      margin: 10px 0;
      &.space {
        margin-top: 10px;
      }
    }
  }
  .getwell_other {
    padding: 3em 0 3em;
    .heading {
      margin-bottom: 0;
      h1 {
        font-size: 38px;
      }
    }
    .security_list {
      flex-wrap: wrap;
      li {
        width: 43%;
        margin: 10px;
      }
    }
  }
  .features {
    padding: 3em 0;
    .feature_box {
      height: auto;
      .icons {
        img {
          width: 80px;
        }
      }
      h1 {
        font-size: 23px;
        line-height: 35px;
        margin-bottom: 15px;
      }
      p {
        font-size: 13px;
        line-height: 25px;
      }
    }
  }
  //offer_banner
  .offer_banner {
    img.feature {
      width: 300px;
    }
    &:after {
      bottom: -15%;
    }
  }
  .offer_feature {
    padding: 5em 0 3em;
    &:after {
      bottom: -42px;
    }
    .feature_box {
      text-align: center;
    }
  }
  .student_lyfcycle {
    .student_lyfcycle_process {
      margin: 30px 0;
      background: transparent;
      ul {
        flex-wrap: wrap;
        justify-content: center;
        li {
          width: 30%;
        }
      }
    }
    .heading {
      h1,
      h2 {
        font-size: 20px;
        line-height: 25px;
      }
    }
    .workflow {
      height: auto;
      h1 {
        font-size: 22px;
        line-height: 30px;
      }
      img.dotted {
        width: 150px;
        bottom: 8%;
      }
    }
  }

  //success_story
  .success_story {
    padding: 3em 0 2em;
    .heading {
      h2 {
        text-align: center;
        font-size: 30px;
      }
    }
    .wraped {
      height: auto;
      .desc {
        h1 {
          font-size: 23px;
        }
        .icon_box {
          flex-direction: column;
          gap: 20px;
          .icon {
            margin-right: 0;
          }
        }
        .name {
          text-align: center;
          h3 {
            font-size: 20px;
          }
        }
        p {
          font-size: 13px;
          text-align: center;
        }
      }
    }
  }
  .proud_customer {
    &:after {
      bottom: -9%;
    }
    .heading {
      h1 {
        font-size: 25px;
      }
    }
  }
  .proud_customer_list {
    padding: 8em 0 1em;
    .list_box {
      margin-bottom: 0;
    }
  }
  .contact_form {
    padding: 0em 0 4em;
  }
}
